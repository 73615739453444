import { User } from '../components/Profile/interface/profileInterface'

export const DefaultFormData: User = {
  accountId: '',
  accountName: '',
  description: '',
  email: '',
  phoneNumber: '',
  familyName: '',
  givenName: '',
  locale: '',
  language: '',
  country: '',
  saving: false,
  isLoading: true,
  profileApiError: '',
  resourceId: '',
  userId: '',
  region: ''
}

export const LocaleStrings = {
  retryButton: 'application.myAccount.profile.error.retrieve.buttons.retry',
  successMessage: 'application.myAccount.modal.changeSuccess',
  failureMessage: 'application.myAccount.modal.unsaved',
  confirmEmailTitle: 'application.myAccount.profile.emailConfirmation.title',
  confirmEmailBody: 'application.myAccount.profile.emailConfirmation.body',
  confirmEmailButton: 'application.myAccount.profile.emailConfirmation.button',
  countries: 'application.myAccount.profile.countries',
  emailHelperText: 'application.myAccount.profile.emailNote',
  selectCountrylabel: 'application.myAccount.profile.selectCountry',
  languageLabel: 'application.myAccount.profile.language',
  selectLanguageSmall: 'application.myAccount.profile.language2',
  applyChangesButtonText: 'application.myAccount.profile.button.applyChanges',
  cancelButtonText: 'application.myAccount.profile.button.cancel',
  titleText: 'application.myAccount.profile.mainHeader',
  titleDescription: 'application.myAccount.profile.description',
  titleDescriptionPassword: 'application.myAccount.profile.description2',
  firstNameLabel: 'application.myAccount.profile.firstName',
  familyNameLabel: 'application.myAccount.profile.familyName',
  emailLabel: 'application.myAccount.profile.email',
  phoneNumberLabel: 'application.myAccount.profile.phoneNumber',
  invalidEmailMessage: 'application.myAccount.profile.constraints.invalidEmail',
  invalidPhoneNumberMessage:
    'application.myAccount.profile.constraints.invalidPhoneNumber',
  confirmModalTitle: 'application.myAccount.modal.confirmModalTitle',
  confirmModalText: 'application.myAccount.modal.toastLeavePage',
  organizationUidLabel: 'application.myAccount.profile.organization.uid',
  organizationNameLabel: 'application.myAccount.profile.organization.name',
  organizationDescriptionLabel:
    'application.myAccount.profile.organization.description',
  organizationTab: 'application.myAccount.profile.organization.tab',
  personalTab: 'application.myAccount.profile.personal',
  personalInformationLabel: 'application.myAccount.personalInformation',
  organizationInformationLabel: 'application.myAccount.organizationInformation',
  descriptionLabel: 'application.myAccount.description'
}

export const PERSONAL_INFO_TAB_READ_PERM = [
  { scope: 'ws-hp.com/usermgtsvc/users.READ' }
]

export const PERSONAL_INFO_TAB_WRITE_PERM = [
  { scope: 'ws-hp.com/usermgtsvc/users.WRITE' }
]

export const ORG_INFO_TAB_READ_PERM = [
  { scope: 'ws-hp.com/accountmgtsvc/accounts.READ' }
]

export const ORG_INFO_TAB_WRITE_PERM = [
  { scope: 'ws-hp.com/accountmgtsvc/accounts.WRITE' }
]
